import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { useQuery } from "@tanstack/react-query";
import { sumBy } from "lodash";
import {
	type ComponentProps,
	Fragment,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useTitle } from "react-use";
import { InvoicingCheckGrid } from "./InvoicingCheckGrid";
import { TypedDropDownButton } from "./TypedDropDownButton";
import {
	NewJobStatus,
	TableNameType,
	type TypedGridColumnProps,
	invoiceTypeNames,
	jobApi,
	jobStatusNamesAndColors,
	toCurrency,
	toDatetimeString,
	toasted,
	uncheckGridSelection,
} from "./helpers";
import {
	useAuditForm,
	useGenericDateRangePicker,
	useGenericInvoicingCheckStatusFilter,
} from "./helpersReact";

type InvoicingCheckGridProps = ComponentProps<typeof InvoicingCheckGrid>;
type InvoicingCheck = InvoicingCheckGridProps["data"][number];

type ActionItem = {
	text?: string;
	action: InvoicingCheckGridAction;
	data: InvoicingCheck;
};

type InvoicingCheckGridAction =
	| "view"
	| "previewInvoice"
	| "sendToOperations"
	| "allowAudit";

type StatusItem = {
	text?: string;
	action: InvoicingCheckGridAction;
	data: InvoicingCheck;
};

const Status = ({ statusString }: { statusString: string }) => (
	<span>
		<Icon name="circle" />
		{statusString}
	</span>
);

const StatusItemRender = ({ item }: { item: StatusItem }) => (
	<Status statusString={item.text ?? ""} />
);
const useHandleAction = (
	retry: () => void,
	showAuditFor: (id: string | number) => void,
) => {
	const navigate = useNavigate();
	const location = useLocation();
	return useCallback(
		(item: ActionItem): undefined => {
			switch (item.action) {
				case "view":
					navigate(`/invoicing-check/${item.data.id}`, {
						state: { from: location.pathname },
					});
					break;
				case "previewInvoice":
					toasted(
						jobApi.invoice
							.invoiceReportInvoiceDetail(item.data.id, {
								format: "blob",
							})
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank")),
						"Preview Invoice",
					);
					break;
				case "sendToOperations":
					toasted(
						jobApi.job
							.jobChangeStatusCreate(item.data.id, NewJobStatus.COMPLETED)
							.then(retry),
						"Sent to Operations",
					);
					break;
				case "allowAudit":
					showAuditFor(item.data.id);
					break;
				default:
					return item.action;
			}
		},
		[location.pathname, navigate, showAuditFor, retry],
	);
};
const useExtraColumns = (handleAction: (item: ActionItem) => undefined) => {
	return useMemo(() => {
		const _columns: TypedGridColumnProps<InvoicingCheck>[] = [
			{
				title: "Actions",
				cell: ({ dataItem }) => {
					const items: StatusItem[] = [];

					items.push({
						action: "previewInvoice",
						data: dataItem,
						text: "Preview Invoice",
					});

					items.push({
						text: "Audit",
						action: "allowAudit",
						data: dataItem,
					});

					if (
						[
							NewJobStatus.READY_FOR_INVOICE,
							NewJobStatus.READY_FOR_REINVOICE,
						].includes(dataItem.status)
					) {
						items.push({
							action: "sendToOperations",
							data: dataItem,
							text: "Send to Operations",
						});
					}
					if (dataItem.statusString) {
						return (
							<td>
								<Button
									size="small"
									icon="eye"
									onClick={() =>
										handleAction({ action: "view", data: dataItem })
									}
								/>
								<TypedDropDownButton
									size="small"
									icon="more-vertical"
									items={items}
									itemRender={StatusItemRender}
									onItemClick={(x) => handleAction(x.item)}
									popupSettings={{ animate: false }}
								/>
							</td>
						);
					}
				},
				field: "actions",
				width: "70px",
			},
		];
		return _columns;
	}, [handleAction]);
};
const useFetchData = (rangeValues: SelectionRange, statusValues: number[]) => {
	const _jobs = useQuery({
		queryKey: [
			"jobApi.invoice.invoiceList",
			rangeValues?.start,
			rangeValues?.end,
			statusValues,
		],
		queryFn: () =>
			jobApi.invoice
				.invoiceList({
					DateFrom: rangeValues.start?.toISOString(),
					DateTo: rangeValues.end?.toISOString(),
					StatusIds: statusValues,
				})
				.then((x) => x.data.data),
		initialData: [],
		refetchInterval: 1000 * 30, // 30 seconds
	});
	const jobs = useMemo(
		() =>
			_jobs.data.map((item) => {
				const endDate = new Date(item.endDate);
				const startDate = new Date(item.startDate);
				const invoiceDate = item.invoiceDate
					? new Date(item.invoiceDate)
					: undefined;
				const invoicingCheck: InvoicingCheck = {
					id: item.id,
					uniqueId: item.uniqueId ?? "",
					vatRate: item.vatRate ?? undefined,
					vatRateString: item.vatRate ? `${item.vatRate}%` : "",
					assignedToName: item.assignedToName ?? "",
					cost: item.cost ?? undefined,
					costString: item.cost
						? toCurrency(item.cost, item.customerCurrencyCode ?? undefined)
						: "",
					price: item.price ?? undefined,
					customerId: item.customerId,
					customerName: item.customerName ?? "",
					customerInvoiceTypeString: invoiceTypeNames[item.customerInvoiceType],
					customerCurrencyCode: item.customerCurrencyCode ?? undefined,
					grossSum: item.grossSum ?? undefined,
					grossSumString: item.grossSum
						? toCurrency(item.grossSum, item.customerCurrencyCode ?? undefined)
						: "",
					invoiceNumber: item.invoiceNumber ?? "",
					purchaseOrderNumber: item.purchaseOrderNumber ?? "",
					status: item.status,
					statusString: jobStatusNamesAndColors[item.status].name,
					totalLegsWithPods: item.totalLegsWithPods,
					endDate,
					endDateString: toDatetimeString(endDate),
					startDate,
					startDateString: toDatetimeString(startDate),
					invoiceDate,
					invoiceDateString: invoiceDate ? toDatetimeString(invoiceDate) : "",
					totalLegsNeedingPods: item.totalLegsNeedingPods,
					ratioPodsString: `${item.totalLegsWithPods}/${item.totalLegsNeedingPods}`,
				};
				return invoicingCheck;
			}),
		[_jobs.data],
	);
	return { data: jobs, retry: _jobs.refetch, loading: _jobs.isFetching };
};
const useSelected = (invoicingCheck: InvoicingCheck[]) => {
	const [selected, setSelected] = useState<Set<number>>(new Set());
	const selectedInvoices = useMemo(
		() => invoicingCheck.filter((x) => selected.has(x.id)),
		[invoicingCheck, selected],
	);
	const selectedCustomerCurrencyCode = useMemo(
		() => selectedInvoices[0]?.customerCurrencyCode,
		[selectedInvoices],
	);
	const selectedTotalGrossSum = useMemo(
		() => selectedInvoices.reduce((acc, x) => acc + (x.grossSum ?? 0), 0),
		[selectedInvoices],
	);
	const buttonText = useMemo(
		() =>
			selected.size
				? `Mark as checked (${selected.size} for ${toCurrency(
						selectedTotalGrossSum,
						selectedCustomerCurrencyCode,
					)})`
				: "Mark as checked",
		[selected.size, selectedTotalGrossSum, selectedCustomerCurrencyCode],
	);
	return { selected, setSelected, buttonText };
};

const useExtraFilters = () => {
	const [dateRangeEl, rangeValues] =
		useGenericDateRangePicker("InvoicingCheck");
	const [filterStatusEl, statusValues] =
		useGenericInvoicingCheckStatusFilter("InvoicingCheck");
	return [
		<Fragment key="extraFilters">
			{filterStatusEl}
			{dateRangeEl}
		</Fragment>,
		rangeValues,
		statusValues,
	] as const;
};

export const InvoicingCheckPage2 = () => {
	useTitle("Invoicing Check");
	const [extraFilters, rangeValues, statusValues] = useExtraFilters();
	const { showAuditFor, auditDialog } = useAuditForm(TableNameType.Job);
	const { data, retry, loading } = useFetchData(rangeValues, statusValues);
	const handleAction = useHandleAction(retry, showAuditFor);
	const extraColumns = useExtraColumns(handleAction);
	const { selected, setSelected, buttonText } = useSelected(data);

	const handleCheck = async () => {
		const selectedCheckboxIds = Array.from(selected);
		const promises: Promise<unknown>[] = [];
		for (const value of selectedCheckboxIds) {
			const job = data.find((x) => x.id === value);
			if (!job) continue;
			if (job.status === NewJobStatus.READY_FOR_INVOICE) {
				promises.push(
					jobApi.job.jobChangeStatusCreate(value, NewJobStatus.CHECKED),
				);
			}
			if (job.status === NewJobStatus.READY_FOR_REINVOICE) {
				promises.push(
					jobApi.job.jobChangeStatusCreate(value, NewJobStatus.CHECKED_AGAIN),
				);
			}
		}
		await toasted(Promise.all(promises), "Marked as checked");
		retry();
		uncheckGridSelection();
	};

	return (
		<>
			{auditDialog}
			<InvoicingCheckGrid
				onChange={(x) =>
					toasted(jobApi.job.jobPartialUpdate(x).finally(retry), "Saved")
				}
				data={data}
				extraColumns={extraColumns}
				extraFilters={extraFilters}
				refresh={retry}
				loading={loading}
				onSelectionChange={setSelected}
				extraButtons={
					<Button
						icon="plus"
						themeColor="primary"
						onClick={handleCheck}
						disabled={!selected.size}
					>
						{buttonText}
					</Button>
				}
				footer={{
					price: (data) => <td>{toCurrency(sumBy(data, "price") || 0)}</td>,
					grossSum: (data) => (
						<td>{toCurrency(sumBy(data, "grossSum") || 0)}</td>
					),
					cost: (data) => <td>{toCurrency(sumBy(data, "cost") || 0)}</td>,
				}}
			/>
		</>
	);
};
