import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@progress/kendo-react-buttons";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, type ReactNode, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, boolean, number, object, string } from "yup";
import { DFlex } from "./GenericFlex";
import {
	GenericForm,
	ICheckbox,
	IMultiCreate,
	ISelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "./GenericForm";
import { GenericPage } from "./GenericPage";
import type {
	CustomerStatusType,
	InvoiceFrequencyType,
	InvoiceSentMethodType,
	InvoiceType,
} from "./api/JobApi";
import {
	type TypedGridColumnProps,
	customerStatusNames,
	invoiceFrequencyNames,
	invoiceSentMethodNames,
	invoiceTypeNames,
	jobApi,
	toasted,
	useLookupChecklists,
	useLookupCurrencies,
	useLookupCustomerStatuses,
	useLookupInvoiceFrequencies,
	useLookupInvoiceSentMethods,
	useLookupInvoiceTemplates,
	useLookupInvoiceTypes,
} from "./helpers";
import { useDialog } from "./helpersReact";

const CustomerSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name¹"),
	accountCode: string().required().label("Account Code¹"),
	address: string().label("Address¹"),
	checklistId: number().label("Checklist"),
	currencyId: number().required().label("Currency¹"),
	emails: array()
		.of(string().email().required().label("Email"))
		.label("Emails¹"),
	includePodAttachmentToInvoiceEmail: boolean()
		.required()
		.label("Include POD Attachment to Invoice Email"),
	invoiceFrequency: number().required().label("Invoice Frequency"),
	invoiceSentMethod: number().required().label("Invoice Sent Method"),
	invoiceTemplate: string().label("Invoice Template"),
	invoiceType: number().required().label("Invoice Type"),
	isEuCustomer: boolean().required().label("Is EU Customer¹"),
	isInSync: boolean().required().label("Is In Sync"),
	isPurchaseOrderNumberMandatory: boolean()
		.required()
		.label("Is Purchase Order Required"),
	mergeAttachmentInOneFile: boolean()
		.required()
		.label("Merge Attachment in One File"),
	phones: array().of(string().required().label("Phone")).label("Phones¹"),
	status: number().required().label("Status¹"),
	vatNumber: string().label("VAT Number¹"),
});
type CustomerFormObject = InferType<typeof CustomerSchema>;

type CustomerFormProps = {
	defaultValues?: Partial<CustomerFormObject>;
	onSubmit: (data: CustomerFormObject) => void;
	lCustomerStatuses: LoadOptionsFn;
	lInvoiceSentMethods: LoadOptionsFn;
	lChecklists: LoadOptionsFn;
	lInvoiceTemplates: LoadOptionsFn;
	lInvoiceTypes: LoadOptionsFn;
	lInvoiceFrequencies: LoadOptionsFn;
	lCurrencies: LoadOptionsFn;
};

const CustomerForm = ({
	defaultValues,
	onSubmit,
	lCustomerStatuses,
	lInvoiceSentMethods,
	lChecklists,
	lInvoiceTemplates,
	lInvoiceTypes,
	lInvoiceFrequencies,
	lCurrencies,
}: CustomerFormProps) => {
	const form = useForm<CustomerFormObject>({
		resolver: yupResolver(CustomerSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={CustomerSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<ISelect n="status" l={lCustomerStatuses} />
					<IText n="name" />
					<IMultiCreate n="phones" />
					<IMultiCreate n="emails" />
				</div>
				<div>
					<ISelect n="invoiceSentMethod" l={lInvoiceSentMethods} />
					<ISelect n="checklistId" l={lChecklists} />
					<ISelect n="invoiceTemplate" l={lInvoiceTemplates} />
					<ISelect n="invoiceType" l={lInvoiceTypes} />
					<ISelect n="invoiceFrequency" l={lInvoiceFrequencies} />
					<ICheckbox n="includePodAttachmentToInvoiceEmail" />
					<ICheckbox n="mergeAttachmentInOneFile" />
				</div>
				<div>
					<ITextArea n="address" />
					<IText n="accountCode" />
					<IText n="vatNumber" />
					<ISelect n="currencyId" l={lCurrencies} />
					<ICheckbox n="isPurchaseOrderNumberMandatory" />
					<ICheckbox n="isEuCustomer" />
				</div>
			</DFlex>
			<p>¹ Fields managed by Sage</p>
		</GenericForm>
	);
};

const CustomerFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<CustomerFormProps, "onSubmit" | "defaultValues">) => (
	<CustomerForm
		defaultValues={defaultValues}
		lCustomerStatuses={useLookupCustomerStatuses()}
		lInvoiceSentMethods={useLookupInvoiceSentMethods()}
		lChecklists={useLookupChecklists()}
		lInvoiceTemplates={useLookupInvoiceTemplates(2)}
		lInvoiceTypes={useLookupInvoiceTypes()}
		lInvoiceFrequencies={useLookupInvoiceFrequencies()}
		lCurrencies={useLookupCurrencies()}
		onSubmit={async (data) => {
			const { id, ...rest } = data;
			const newRest = {
				...rest,
				invoiceFrequency: rest.invoiceFrequency as InvoiceFrequencyType,
				invoiceSentMethod: rest.invoiceSentMethod as InvoiceSentMethodType,
				invoiceType: rest.invoiceType as InvoiceType,
				status: rest.status as CustomerStatusType,
			};
			const processData = async () => {
				if (id) await jobApi.customer.customerUpdate({ id, ...newRest });
				else await jobApi.customer.customerCreate({ ...newRest });
				onSubmit(data);
			};
			await toasted(
				processData(),
				id ? "Updating customer" : "Creating customer",
			);
		}}
	/>
);

type Customer = CustomerFormObject & {
	id: number;
	statusString: string;
	checklistString: string;
	currencyString: string;
	includePodAttachmentToInvoiceEmailString: string;
	invoiceFrequencyString: string;
	invoiceSentMethodString: string;
	invoiceTypeString: string;
	isEuCustomerString: string;
	isInSyncString: string;
	isPurchaseOrderNumberMandatoryString: string;
	mergeAttachmentInOneFileString: string;
};

const defaultColumns: TypedGridColumnProps<Customer>[] = [
	{ field: "name", title: "Name" },
	{ field: "statusString", title: "Status" },
	{ field: "emails", title: "Emails" },
	{ field: "phones", title: "Phones" },
	{ field: "vatNumber", title: "VAT Number" },
	{ field: "address", title: "Address" },
	{ field: "accountCode", title: "Account Code" },
	{ field: "currencyString", title: "Currency" },
	{ field: "checklistString", title: "Checklist", hidden: true },
	{
		field: "includePodAttachmentToInvoiceEmailString",
		title: "Include POD Attachment to Invoice Email",
		hidden: true,
	},
	{ field: "invoiceFrequencyString", title: "Invoice Frequency", hidden: true },
	{
		field: "invoiceSentMethodString",
		title: "Invoice Sent Method",
		hidden: true,
	},
	{ field: "invoiceTemplate", title: "Invoice Template", hidden: true },
	{ field: "invoiceTypeString", title: "Invoice Type", hidden: true },
	{ field: "isEuCustomerString", title: "Is EU Customer", hidden: true },
	{ field: "isInSyncString", title: "Is In Sync", hidden: true },
	{
		field: "isPurchaseOrderNumberMandatoryString",
		title: "Is Purchase Order Required",
		hidden: true,
	},
	{
		field: "mergeAttachmentInOneFileString",
		title: "Merge Attachment in One File",
		hidden: true,
	},
];

const useFetchData = (): ComponentProps<
	typeof GenericPage<Customer>
>["data"] => {
	const _customers = useQuery({
		queryKey: ["jobApi.customer.customerList"],
		queryFn: () => jobApi.customer.customerList({}).then((x) => x.data.data),
		initialData: [],
	});
	const _curencies = useQuery({
		queryKey: ["jobApi.currency.currencyLookupList"],
		queryFn: () => jobApi.currency.currencyLookupList({}).then((x) => x.data),
		initialData: [],
	});
	const customers = useMemo(
		() =>
			_customers.data.map(
				(x): Customer => ({
					id: x.id,
					status: x.status,
					statusString: customerStatusNames[x.status] ?? "",
					name: x.name,
					emails: x.emails,
					phones: x.phones,
					address: x.address ?? undefined,
					accountCode: x.accountCode,
					checklistId: x.checklist?.id,
					checklistString: x.checklist?.name ?? "",
					currencyId: x.currency.id,
					currencyString:
						_curencies.data.find((y) => y.id === x.currency.id)?.code ?? "",
					includePodAttachmentToInvoiceEmail:
						x.includePodAttachmentToInvoiceEmail,
					includePodAttachmentToInvoiceEmailString:
						x.includePodAttachmentToInvoiceEmail ? "Yes" : "No",
					vatNumber: x.vatNumber ?? undefined,
					invoiceFrequency: x.invoiceFrequency,
					invoiceFrequencyString:
						invoiceFrequencyNames[x.invoiceFrequency] ?? "",
					invoiceSentMethod: x.invoiceSentMethod,
					invoiceSentMethodString:
						invoiceSentMethodNames[x.invoiceSentMethod] ?? "",
					invoiceTemplate: x.invoiceTemplate ?? undefined,
					invoiceType: x.invoiceType,
					invoiceTypeString: invoiceTypeNames[x.invoiceType] ?? "",
					isEuCustomer: x.isEuCustomer,
					isEuCustomerString: x.isEuCustomer ? "Yes" : "No",
					isInSync: x.isInSync,
					isInSyncString: x.isInSync ? "Yes" : "No",
					isPurchaseOrderNumberMandatory: x.isPurchaseOrderNumberMandatory,
					isPurchaseOrderNumberMandatoryString: x.isPurchaseOrderNumberMandatory
						? "Yes"
						: "No",
					mergeAttachmentInOneFile: x.mergeAttachmentInOneFile,
					mergeAttachmentInOneFileString: x.mergeAttachmentInOneFile
						? "Yes"
						: "No",
				}),
			),
		[_customers.data, _curencies.data],
	);
	return {
		data: customers,
		retry: _customers.refetch,
		loading: _customers.isFetching,
	};
};

type SyncWithSageProps = {
	loading: boolean;
	handleConfirm: () => void;
	toggleDialog: () => void;
};

const SyncWithSage = ({
	handleConfirm,
	toggleDialog,
	loading,
}: SyncWithSageProps) => {
	return (
		<>
			Are you sure you want to sync customers with Sage?
			<DialogActionsBar>
				<Button onClick={toggleDialog} disabled={loading}>
					No
				</Button>
				<Button onClick={handleConfirm} autoFocus disabled={loading}>
					Yes
				</Button>
			</DialogActionsBar>
		</>
	);
};

export const CustomersPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.customer.customerDelete(id).then(data.retry),
			"Deleting Customer",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: CustomerFormObject) => void,
	) => {
		let defaultValues: Partial<CustomerFormObject> = {
			status: 1,
			currencyId: 1,
			invoiceFrequency: 0,
			invoiceSentMethod: 1,
			invoiceType: 1,
			isEuCustomer: true,
			isInSync: false,
			isPurchaseOrderNumberMandatory: true,
			includePodAttachmentToInvoiceEmail: true,
			mergeAttachmentInOneFile: true,
		};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<CustomerFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
		);
	};

	const [dialogTitle, setDialogTitle] = useState<string>();
	const [dialogContent, setDialogContent] = useState<ReactNode>();
	const [toggleDialog, dialog] = useDialog(dialogContent, dialogTitle);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const getSyncWithSageContent = () => (
		<SyncWithSage
			loading={isSubmitting}
			toggleDialog={toggleDialog}
			handleConfirm={async () => {
				toggleDialog();

				const sync = async () => {
					setIsSubmitting(true);

					await jobApi.customer
						.customerSyncList()
						.finally(() => setIsSubmitting(false));
				};

				await toasted(sync().then(data.retry), "Sage synchronisation");
			}}
		/>
	);

	const handleSyncWithSage = () => {
		setDialogTitle("Sync with Sage");
		setDialogContent(getSyncWithSageContent());
		toggleDialog();
	};

	return (
		<>
			<GenericPage
				pageTitle="Customers"
				name="Customer"
				data={data}
				onDelete={handleDelete}
				defaultColumns={defaultColumns}
				getForm={getForm}
				extraButtons={
					<Button
						icon="import"
						themeColor="secondary"
						disabled={isSubmitting}
						onClick={handleSyncWithSage}
					>
						Sync with Sage
					</Button>
				}
			/>
			{dialog}
		</>
	);
};
